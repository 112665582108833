// Primary Break Points
$bp-xsmall: 479px;
$bp-small: 599px;
$bp-medium: 820px;
$bp-large: 979px;
$bp-xlarge: 1024px;
$bp-xxlarge: 1140px;

// color
$fstar-green: #9eba11;
$fstar-blue: #042237;
$fstar-dark-blue: #052338;
$fstar-super-dark-blue: #05111a;
$fstar-grey-light: #f6f6f6;
$fstar-grey-soft: rgba(0, 0, 0, 0.12);
$fstar-grey-dark: #d8d8d8;
$fstar-grey-mine-shaft: #2a2a2a;
$fstar-grey: #666666;
$fstar-grey-ivory: #cdcdc1;
$fstar-deep-sky-blue: #005981;
$fstar-grey-super-light: #fafafa;
$fstar-grey-darker: #eeeeee;
$fstar-grey-medium: rgba(204, 204, 204, 0.25);
$fstar-orange: #f1953e;
$fstar-dark-orange: #e56c00;
$anchor-default-color: #0084c1;
$fstar-grey-80: #cccccc;
$fstar-grey-80-light: rgba(204, 204, 204, 0.4);
$import-database-list-add-base-button-color: #f1f1f1;
$fstar-old-gold: #cfc32e;
$fstar-falu-red: #87241a;
$fstar-hippie-green: #6b9243;
$fstar-blue-malibu: #76cfff;
$fstar-purple: #9013fe;
$fstar-blue-cobalt: #035aa6;
$fstar-yellow-dolly: #fff587;
$fstar-brown-contessa: #bf7256;
$fstar-keppel-blue: #3db3ad;
$fstar-wine-berry-purple: #681c60;
$fstar-marigold-yellow: #bf8a26;
$fstar-hawaiian-tan-orange: #a64b17;

$airbus-blue-selected: #167dde;
$airbus-blue-dark: #001a5c;
$airbus-blue-grey: #4d5f8d;
$airbus-blue-tasking: #1697de;

$c-main: $fstar-green;

$fstar-font: Lato;
