@import '~@angular/material/theming';
@import 'scss/var';
// Plus imports for other components in your app.
@function custom-mat-light-theme-foreground($color) {
  @return (
    base: $color,
    divider: $black-12-opacity,
    dividers: $black-12-opacity,
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-min: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$general-typography: mat-typography-config(
  $font-family: 'Lato, Roboto, sans-serif',
);

// Be sure that you only ever include this mixin once!
@include mat-core($general-typography);

$tab-config: mat-typography-config(
  $font-family: 'Lato, Roboto, sans-serif',
  $button: mat-typography-level(12px, 12px, normal, 'Lato'),
);

// Be sure that you only ever include this mixin once!
@include mat-core($general-typography);
@include mat-tabs-typography($tab-config);

$fstar-green-palette: (
  50: #f3f7e2,
  100: #e2eab8,
  200: #cedd88,
  300: #bacf58,
  400: #acc435,
  500: #9dba11,
  600: #95b30f,
  700: #8bab0c,
  800: #81a30a,
  900: #6f9405,
  A100: #edffc0,
  A200: #deff8d,
  A400: #cfff5a,
  A700: #c8ff41,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$fstar-blue-palette: (
  50: #e1e4e7,
  100: #b4bdc3,
  200: #82919b,
  300: #4f6473,
  400: #2a4355,
  500: #042237,
  600: #031e31,
  700: #03192a,
  800: #021423,
  900: #010c16,
  A100: #5693ff,
  A200: #2373ff,
  A400: #0057ef,
  A700: #004ed5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$fstar-app-primary: mat-palette($fstar-blue-palette);
$fstar-app-accent: mat-palette($fstar-green-palette, 500);
$fstar-app-warn: mat-palette($mat-red);
// Create the theme object (a Sass parcels-map containing all of the palettes).
$fstar-app-theme: mat-light-theme($fstar-app-primary, $fstar-app-accent, $warn: mat-palette($mat-red));
// Set custom foreground color
$custom-foreground: custom-mat-light-theme-foreground($fstar-dark-blue);
// Since all maps in SCSS are immutable the parcels-map-merge() returns new parcels-map instance and DOES NOT modify the parcels-map in place
// - thus we have another variable $fstar-app-theme-custom to hold the result theme.
$fstar-app-theme-custom: map_merge(
  $fstar-app-theme,
  (
    foreground: $custom-foreground,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($fstar-app-theme-custom);
$fstar-grey-palette: (
  50: #fafafa,
  100: #f3f3f3,
  200: #ececec,
  300: #e4e4e4,
  400: #dedede,
  500: #d8d8d8,
  600: #d4d4d4,
  700: #cecece,
  800: #c8c8c8,
  900: #bfbfbf,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
.alternate-grey-theme {
  $alternate-grey-primary: mat-palette($fstar-grey-palette, 200);
  $alternate-grey-accent: mat-palette($fstar-grey-palette, 400);
  $alternate-grey-theme: mat-light-theme($alternate-grey-primary, $alternate-grey-accent);
  @include mat-slide-toggle-theme($alternate-grey-theme);
}
// global underline thick height for mat form field
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba($fstar-grey, 0.5);
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  height: 1px;
}
.mat-form-field.mat-focused:not(.mat-form-field-invalid) .mat-form-field-ripple {
  background-color: $fstar-grey;
}
